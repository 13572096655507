import React, { useContext } from "react";
import "./Login.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";

import moment from "moment-timezone";
import { DataContext } from "../../Context";
import logo from "../Image/logo.png";

function Login(props) {
  const navigation = useNavigate();
  const formobj = {
    email: "",
    password: "",
  };

  const [formstate, setformState] = useState(formobj);
  const [formerror, setFormerror] = useState({});
  const [err, seterr] = useState("");
  const [apidata, setApidata] = useState();
  const localContext = useContext(DataContext);
  console.log(process.env.REACT_APP_PUBLIC_URL);

  const { contextState, setContextState } = localContext;

  const randomToken = () => {
    // function randomToken() {
    var characters =
      "abcdefghiklmnopqrstuvwxyzABCDEFGHIKLMNOPQRSTUVWXYZ0123456789!@#$%^&*?_+-=/";

    var lenString = 26;
    var randomstring = "";

    //loop to select a new character in each iteration
    for (var i = 0; i < lenString; i++) {
      var rnum = Math.floor(Math.random() * characters.length);
      randomstring += characters.substring(rnum, rnum + 1);
    }
    return randomstring;
  };

  const handlechange = (e) => {
    setformState({ ...formstate, [e.target.id]: e.target.value });
  };
  const handleSubmit = () => {
    const done = validation();
    if (done) {
      callApi();
    }
  };

  const callApi = () => {
    var tokken = randomToken();
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(formstate),
      tokken
    ).toString();

    axios({
      method: "post",
      data: { dat: ciphertext },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",

        Authorization: tokken,
      },
      url: `${process.env.REACT_APP_PUBLIC_URL}login_1`,
    }).then(function (res) {
      // setApiloginmatch(res.data);
      const twoMinutesAgo = moment().subtract(2, "minutes");
      if (
        (res.data.error && res.data.data.login_at === "") ||
        (res.data.error && res.data.data.session === "")
      ) {
        navigation("/otp");
        axios({
          method: "post",
          data: {
            userid: res.data.data._id,
            visitedtime: moment().format("YYYY-MM-DD HH:mm:ss"),
            entity: "",
          },
          headers: { "Content-Type": "application/json" },
          url: `${process.env.REACT_APP_PUBLIC_URL}activity`,
        }).then((res) => console.log(res));
        localStorage.setItem("time", moment().format("YYYY-MM-DD HH:mm:s"));
        localStorage.setItem("loginstatus", true);
        localStorage.setItem("userId", res.data.data._id);
        localStorage.setItem("token", randomToken());
        localStorage.setItem("name", res.data.data.fname);
        setContextState({ ...contextState, loginvalue: formstate });
      } else if (
        res.data.error &&
        moment(res.data.data.login_at).isBefore(twoMinutesAgo)
      ) {
        // seterr(res.data.errormsg);
        navigation("/otp");
        axios({
          method: "post",
          data: {
            userid: res.data.data._id,
            visitedtime: moment().format("YYYY-MM-DD HH:mm:ss"),
            entity: "",
          },
          headers: { "Content-Type": "application/json" },
          url: `${process.env.REACT_APP_PUBLIC_URL}activity`,
        }).then((res) => console.log(res));
        localStorage.setItem("time", moment().format("YYYY MMMM Do, h:mm:ss"));
        localStorage.setItem("loginstatus", true);
        localStorage.setItem("userId", res.data.data._id);
        localStorage.setItem("token", randomToken());
        localStorage.setItem("name", res.data.data.fname);
      } else {
        seterr(res.data.errormsg);
      }
    });
  };

  const validation = () => {
    const errorobj = {};
    // var emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (formstate.email === "") {
      errorobj.email = "email is empty";
    }
    if (formstate.password === "") {
      errorobj.password = "password is empty";
    }
    // if (!emailPattern.test(formstate.email)) {
    //   errorobj.email = "Email Format is incorrect";
    // }

    setFormerror(errorobj);

    if (Object.keys(errorobj).length > 0) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    console.log(moment().format("YYYY MMMM Do, h:mm:ss"));
  }, []);
  return (
    <div className="wrapper">
      <div className="container main">
        <div style={{ width: "70%", marginLeft: "20px", marginBottom: "10px" }}>
          <img src={logo} height={60} />
        </div>
        <div
          className="row"
          style={{
            width: "900px",
            height: "450px",
            borderRadius: "10px",
            background: "#fff",
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.2)",
          }}
        >
          <div className="col-md-6 side-image"></div>
          <div className="col-md-6 right">
            <div className="input-box">
              <header>Login Account</header>
              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="email"
                  onChange={handlechange}
                  required
                />
                <label htmlFor="email">Email</label>
              </div>
              {formerror.email}
              <div className="input-field">
                <input
                  type="password"
                  className="input"
                  id="password"
                  onChange={handlechange}
                  required
                />
                <label htmlFor="password">Password</label>
              </div>
              {formerror.password}
              <div className="input-field">
                <button className="submit" onClick={handleSubmit}>
                  Login
                </button>
                {err}
              </div>
              <div className="signin">
                <span>
                  Didn't have an account?{" "}
                  <Link to="/register">Create account</Link>
                </span>
              </div>
              {/* <div className="signin">
                <span>
                  <Link
                    to="/digilocker"
                    className="btn btn-md"
                    style={{ backgroundColor: "#466cd9", color: "white" }}
                  >
                    Digilocker
                  </Link>
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import ProgressBar from "react-bootstrap/ProgressBar";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import { Card } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import "./financial.css";

charts(FusionCharts);
// Preparing the chart data
const chartData = [
  {
    label: "2015-16",
    value: "1071",
  },
  {
    label: "2016-17",
    value: "1041",
  },
  {
    label: "2017-18",
    value: "1042",
  },
  {
    label: "2018-19",
    value: "1018",
  },
  {
    label: "2019-20",
    value: "919",
  },
  {
    label: "2020-21",
    value: "912",
  },
  {
    label: "2021-22",
    value: "912",
  },
];
const shty = {
  chart: {
    // caption: "Countries With Most Oil Reserves [2017-18]",
    // subcaption: "In MMbbl = One Million barrels",
    xaxisname: "Year",
    yaxisname: "Revenue",
    numbersuffix: "M",
    theme: "fusion",
  },
  data: chartData,
};

const Financials = () => {
  let state = useLocation();
  const navigation = useNavigate();
  const [Apifinancial, setApifinancial] = useState();
  const apifin = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setApifinancial(data));
  };
  const options = { maximumFractionDigits: 2 };
  useEffect(() => {
    if ([null, ""].includes(state.state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      apifin(state.state.some);
    }
  }, []);
  return (
    <div>
      <Header />
      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link
            style={{ textDecoration: "none" }}
            to={"/search"}
            state={{
              some:
                state && state.state && state.state.some
                  ? state.state.some
                  : null,
            }}
          >
            {state && state.state && state.state.some ? state.state.some : null}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Financials</Breadcrumb.Item>
      </Breadcrumb>
      <div className="Management">
        <h5>Financials</h5>
        <div className="Management-container"></div>
      </div>

      <div className="details">
        <Card className="text-center" style={{ width: "15%" }}>
          <Card.Body>
            <Card.Title style={{ fontSize: "15px", fontWeight: "700" }}>
              Revenue
            </Card.Title>

            <Card.Text>
              ₹{" "}
              {Apifinancial &&
                Intl.NumberFormat("en-IN", options).format(
                  parseInt(Apifinancial.incom.donations) +
                    parseInt(Apifinancial.incom.Other)
                )}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="text-center" style={{ width: "15%" }}>
          <Card.Body>
            <Card.Title style={{ fontSize: "15px", fontWeight: "700" }}>
              Profit{" "}
            </Card.Title>
            <Card.Text>
              ₹{" "}
              {Apifinancial &&
                Intl.NumberFormat("en-IN", options).format(
                  parseInt(Apifinancial.incom.donations) +
                    parseInt(Apifinancial.incom.Other) -
                    (parseInt(Apifinancial.expenditure.programme) +
                      parseInt(Apifinancial.expenditure.other))
                )}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="text-center" style={{ width: "15%" }}>
          <Card.Body>
            <Card.Title style={{ fontSize: "15px", fontWeight: "700" }}>
              Expenditure
            </Card.Title>
            <Card.Text>
              ₹{" "}
              {Apifinancial &&
                Intl.NumberFormat("en-IN", options).format(
                  parseInt(Apifinancial.expenditure.programme) +
                    parseInt(Apifinancial.expenditure.other)
                )}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="text-center" style={{ width: "15%" }}>
          <Card.Body>
            <Card.Title style={{ fontSize: "15px", fontWeight: "700" }}>
              Admin & Overhead
            </Card.Title>
            <Card.Text>
              ₹{" "}
              {Apifinancial &&
                Intl.NumberFormat("en-IN", options).format(
                  parseInt(Apifinancial.expenditure.other)
                )}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card className="text-center" style={{ width: "15%" }}>
          <Card.Body>
            <Card.Title style={{ fontSize: "15px", fontWeight: "700" }}>
              Programmes
            </Card.Title>
            <Card.Text style={{ fontSize: "13px" }}>
              ₹{" "}
              {Apifinancial &&
                Intl.NumberFormat("en-IN", options).format(
                  parseInt(Apifinancial.expenditure.programme)
                )}
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <br></br>
      {/* <div className='Management-accordion'>
        <Accordion style={{width:'95%'}} defaultActiveKey={['0']} alwaysOpen>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Objective Relevance <Badge style={{marginLeft:"10px"}} bg="success">3.3/10</Badge></Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Geographical Presence<Badge style={{marginLeft:"10px"}} bg="danger">3.3/10</Badge></Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
        <Accordion.Header>Media Sentiments <Badge style={{marginLeft:"10px"}} bg="success">3.3/10</Badge></Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
        <Accordion.Header>Public Sentiments<Badge style={{marginLeft:"10px"}} bg="success">3.3/10</Badge></Accordion.Header>
       
        </Accordion.Item>
    </Accordion>
    </div> */}

      <div className="finchart">
        <ReactFusioncharts
          type="column2d"
          width="100%"
          height="450"
          dataFormat="JSON"
          dataSource={shty}
        />
      </div>
    </div>
  );
};

export default Financials;

// import FusionCharts from "fusioncharts";
// import charts from "fusioncharts/fusioncharts.charts";
// import ReactFusioncharts from "react-fusioncharts";

// // Resolves charts dependancy
// charts(FusionCharts);

// const dataSource = {
//   chart: {
//     caption: "Countries With Most Oil Reserves [2017-18]",
//     subcaption: "In MMbbl = One Million barrels",
//     xaxisname: "Country",
//     yaxisname: "Reserves (MMbbl)",
//     numbersuffix: "K",
//     theme: "gammel"
//   }
// };

// class MyComponent extends React.Component {
//   render() {
//     return (
//       <ReactFusioncharts
//         type="column2d"
//         width="100%"
//         height="100%"
//         dataFormat="JSON"
//         dataSource={dataSource}
//       />
//     );
//   }
// }

import React from "react";
import Login from "./Components/Login/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./Components/Signup/Signup";
import Home from "./Components/Home/Home";
import Ngo from "./Components/NGO/Ngo";
import { About } from "./Components/About/About";
import Management from "./Components/Management/Management";
import Social from "./Components/Social Impact/Social";
import Religious from "./Components/Religious Affiliations/Religious";
import Reputation from "./Components/Reputation/Reputation";
import Financials from "./Components/Financials/Financials";
import Legal from "./Components/Legal Issues/Legal";
import Media from "./Components/Media/Media";
import Public from "./Components/Public/Public";
import Promoters from "./Components/Promoters/Promoters";
import BoardMember from "./Components/BoardMembers/BoardMember";
import Topprojects from "./Components/TopProjects/Topprojects";
import FocusArea from "./Components/FocusArea/FocusArea";
import PresentIn from "./Components/PresentIn/PresentIn";
import Callback from "./Components/Callback/Callback";
import Digilocker from "./Components/Digilocker/Digilocker";
import Protected from "./Components/Protected/Protected";
import Firstpage from "./Components/Firstpage/Firstpage";
import ReactIdleTimer from "./Components/Idle-timer/ReactIdelTimer";
import Otp from "./Components/Otp/Otp";
import Context from "./Context";
import Loader from "./Components/Loader/Loader";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Context>
          <Routes>
            <Route path="/loader" element={<Loader />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Firstpage />} />

            <Route
              path="/home"
              element={
                <Protected>
                  <Home />
                </Protected>
              }
            />
            <Route
              path="/ngo"
              element={
                <Protected>
                  <Ngo />
                </Protected>
              }
            />
            <Route
              path="/about"
              element={
                <Protected>
                  <About />
                </Protected>
              }
            />
            <Route
              path="/management"
              element={
                <Protected>
                  <Management />
                </Protected>
              }
            />
            <Route
              path="/social"
              element={
                <Protected>
                  <Social />
                </Protected>
              }
            />
            <Route
              path="/religious"
              element={
                <Protected>
                  <Religious />
                </Protected>
              }
            />
            <Route
              path="/reputation"
              element={
                <Protected>
                  <Reputation />
                </Protected>
              }
            />
            <Route
              path="/financial"
              element={
                <Protected>
                  <Financials />
                </Protected>
              }
            />
            <Route
              path="/legal"
              element={
                <Protected>
                  <Legal />
                </Protected>
              }
            />
            <Route
              path="/media"
              element={
                <Protected>
                  <Media />
                </Protected>
              }
            />
            <Route
              path="/public"
              element={
                <Protected>
                  <Public />
                </Protected>
              }
            />
            <Route
              path="/promoters"
              element={
                <Protected>
                  <Promoters />
                </Protected>
              }
            />
            <Route
              path="search"
              element={
                <Protected>
                  <Ngo />
                </Protected>
              }
            />
            <Route
              path="board"
              element={
                <Protected>
                  <BoardMember />
                </Protected>
              }
            />
            <Route
              path="/topprojects"
              element={
                <Protected>
                  <Topprojects />
                </Protected>
              }
            />
            <Route
              path="/focusArea"
              element={
                <Protected>
                  <FocusArea />
                </Protected>
              }
            />
            <Route
              path="/presentin"
              element={
                <Protected>
                  <PresentIn />
                </Protected>
              }
            />
            <Route
              path="/callback"
              element={
                <Protected>
                  <Callback />
                </Protected>
              }
            />
            <Route path="digilocker" element={<Digilocker />} />
            <Route path="idle" element={<ReactIdleTimer />} />
            <Route path="/otp" element={<Otp />} />
          </Routes>
        </Context>
      </BrowserRouter>
    </div>
  );
};

export default App;

import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Management.css";
import Header from "../Header/Header";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";

const CatDiv = ({ main, findCat }) => {
  let [res, setRes] = useState([]);
  console.log(main, findCat);

  useEffect(() => {
    let res0 = main.find((cat) => {
      return cat._id === findCat;
    });
    setRes(res0);
  }, []);

  console.log(res && res.art);

  return (
    <div>
      {
        res && res.art ? (
          res.art.map((items) => (
            <>
              <div className="political">
                <div style={{ width: "20%" }}>{items.headline}</div>
                <Link target="_blank" to={items.link}>
                  {items.source}
                </Link>
                <div>{moment(items.date).format("DD-MM-YYYY")}</div>
              </div>
              <hr />
            </>
          ))
        ) : (
          <div>No data</div>
        )
        /* {res.map((items) => (
        <>
          <div className="political">
            <div style={{ width: "20%" }}>{items.headline}</div>
            <Link to={items.link}>{items.source}</Link>
            <div>{moment(items.date).format("DD-MM-YYYY")}</div>
          </div>
          <hr />
        </>
      ))} */
      }
    </div>
  );
};

const Management = () => {
  let state = useLocation();
  const navigation = useNavigate();
  const [apimanagment, setApimanagment] = useState();

  if (apimanagment) {
    var s = apimanagment.analyse.total;
  }
  const apimanagment1 = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setApimanagment(data));
  };
  useEffect(() => {
    if ([null, ""].includes(state.state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      apimanagment1(state.state.some);
    }
  }, []);
  return (
    <div>
      <Header />
      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link
            style={{ textDecoration: "none" }}
            to={"/search"}
            state={{
              some:
                state && state.state && state.state.some
                  ? state.state.some
                  : null,
            }}
          >
            {state && state.state && state.state.some ? state.state.some : null}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Governance</Breadcrumb.Item>
      </Breadcrumb>
      <div className="Management">
        <h5>Governance and Management</h5>
        <div className="Management-container"></div>
      </div>

      <div className="Management-accordion">
        <Accordion style={{ width: "95%" }} defaultActiveKey={["0"]} alwaysOpen>
          {apimanagment && apimanagment.analyse && (
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Political Inclination
                {apimanagment.analyse.category.map(
                  (item) =>
                    item._id === "Political" && (
                      <Badge style={{ marginLeft: "10px" }}>
                        {((item.artcnt / s) * 100).toFixed(2)}%
                      </Badge>
                    )
                )}
              </Accordion.Header>
              <Accordion.Body>
                {/* {apimanagment.analyse.category.map((item) => (
                  <>
                    <div>
                      {item._id === "Political" &&
                        item.art.map((items) => (
                          <>
                            <div className="political">
                              <div style={{ width: "20%" }}>
                                {items.headline}
                              </div>
                              <Link to={items.link}>{items.source}</Link>
                              <div>
                                {moment(items.date).format("DD-MM-YYYY")}
                              </div>
                            </div>
                            <hr />
                          </>
                        ))}
                    </div>
                  </>
                ))} */}
                {
                  // apimanagment.analyse.category
                  //   .filter((para) => para._id.includes("Political"))
                  //   .map((item) =>
                  //     item._id === "Political"
                  //       ? item.art.map((items) => (
                  //           <>
                  //             <div className="political">
                  //               <div style={{ width: "20%" }}>
                  //                 {items.headline}
                  //               </div>
                  //               <Link to={items.link}>{items.source}</Link>
                  //               <div>
                  //                 {moment(items.date).format("DD-MM-YYYY")}
                  //               </div>
                  //             </div>
                  //             <hr />
                  //           </>
                  //         ))
                  //       : "no data"
                  //   )
                }
                <CatDiv
                  main={apimanagment.analyse.category}
                  findCat="Political"
                />
              </Accordion.Body>
            </Accordion.Item>
          )}
          {apimanagment && apimanagment.analyse && (
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Government Inclination
                {apimanagment.analyse.category.map(
                  (item) =>
                    item._id === "Government" && (
                      <Badge style={{ marginLeft: "10px" }}>
                        {((item.artcnt / s) * 100).toFixed(2)}%
                      </Badge>
                    )
                )}
              </Accordion.Header>
              <Accordion.Body>
                <CatDiv
                  main={apimanagment.analyse.category}
                  findCat="Government"
                />
              </Accordion.Body>
            </Accordion.Item>
          )}
          <Accordion.Item eventKey="2">
            <Accordion.Header>Legal Issues </Accordion.Header>
            <Accordion.Body>
              {apimanagment &&
                apimanagment.legal.map((item) => (
                  <>
                    <div className="oxfam-total">
                      <div className="oxfam-tot-sub">
                        <p>Name:{item.name}</p>
                        <p>Judge_name: DIPAK MISRA,C. NAGAPPAN</p>
                        <p>case_number: CRIMINAL APPEAL/1531/2015</p>
                      </div>
                      <div className="oxfam-tot-sub">
                        <p>Date_of_decision: 03-10-2016</p>
                        <p> Disposal_nature: Disposed off</p>
                        <p>Offence:</p>
                      </div>
                    </div>
                    <hr />
                  </>
                ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Management;

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Header from "../Header/Header";
import "./Home.css";
import { BsSearch } from "react-icons/bs";
import { FaFilter } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Image/logo.png";
import axios from "axios";
import moment from "moment";

function Home() {
  const [modalShow, setModalShow] = useState(false);
  const [apidata, setApidata] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [Search, setSearch] = useState("");

  const handlechange = (e) => {
    setSearch(e.target.value);

    if (Search.length > 2) {
      axios({
        method: "post",
        data: { Search },
        headers: { "Content-Type": "application/json" },
        url: `${process.env.REACT_APP_PUBLIC_URL}search1`,
      }).then((res) => {
        setApidata(res.data);
      });
    }
  };

  const handlengo = (cin) => {
    const userid = localStorage.getItem("userId");
    localStorage.setItem("time", moment().format("YYYY-MM-DD HH:mm:ss"));
    console.log(userid);
    axios({
      method: "post",
      data: {
        userid: userid,
        visitedtime: moment().format("YYYY-MM-DD HH:mm:ss"),
        entity: cin,
        pageUrl: `/search/${cin}`,
      },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}activity`,
    }).then((res) => console.log(res));
  };

  const navigation = useNavigate();
  const handlelogout = () => {
    navigation("/");
    const userId = localStorage.getItem("userId");
    const tokken = localStorage.getItem("token");
    axios({
      method: "post",
      data: { userId: userId, tokken: tokken },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}logout`,
    });
  };

  return (
    <>
      <Header />
      <div className="home-parentContainer">
        {/* <div className="logout-home">
          <button onClick={handlelogout}>logout</button>
        </div> */}
        <div className="home-container">
          <h1
            style={{
              fontSize: "60px",
              color: "#fd720d",
            }}
          >
            <BsSearch
              size={50}
              style={{
                marginRight: "10px",
                marginBottom: "10px",
                color: "grey",
              }}
            />
            Search
          </h1>
          <div className="home-subcontainer">
            <Tabs
              defaultActiveKey="home"
              id="fill-tab-example"
              className="mb-3"
              variant="pills"
              justify
            >
              <Tab eventKey="home" title="Entity">
                <div className="input-container">
                  <div className="search-Home">
                    <input
                      style={{ width: "97%", border: "0px" }}
                      placeholder="Name/CIN No."
                      onChange={handlechange}
                    />{" "}
                    <BsSearch />
                  </div>

                  <p
                    className="modal-button"
                    onClick={() => setModalShow(true)}
                  >
                    <FaFilter />
                    <span style={{ marginLeft: "5px" }}> Advance Search</span>
                  </p>

                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
                <div className="dropdown">
                  {apidata.map((items) => (
                    //<Link to="new-path" state={{ some: "value" }} />
                    <Link
                      to={"/search"}
                      state={{ some: items.COMPANY_NAME }}
                      style={{ textDecoration: "none" }}
                    >
                      {" "}
                      <div
                        className="dropdown-list"
                        onClick={() => handlengo(items.COMPANY_NAME)}
                      >
                        {items.COMPANY_NAME} <span>({items.CIN})</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </Tab>
              <Tab eventKey="profile" title="Individual">
                <div className="input-container">
                  <div className="search-Home">
                    <input
                      style={{ width: "97%", border: "0px" }}
                      placeholder="Name/Pan Card No./Adhaar Card No."
                      onChange={handlechange}
                    />{" "}
                    <BsSearch />
                  </div>

                  <p
                    className="modal-button"
                    onClick={() => setModalShow(true)}
                  >
                    <FaFilter />
                    <span style={{ marginLeft: "5px" }}> Advance Search</span>
                  </p>

                  <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

function MyVerticallyCenteredModal(props) {
  const modleObject = {
    cin: "",
    reagistration: "",
    company_type: "",
    city: "",
    name: "",
    roc_location: "",
    category: "",
    state: "",
  };
  const handlengo = (cin) => {
    const userid = localStorage.getItem("userId");
    localStorage.setItem("time", moment().format("YYYY-MM-DD HH:mm:ss"));
    console.log(userid);
    axios({
      method: "post",
      data: {
        userid: userid,
        visitedtime: moment().format("YYYY-MM-DD HH:mm:ss"),
        entity: cin,
        pageUrl: `/search/${cin}`,
      },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}activity`,
    }).then((res) => console.log(res));
  };

  const [modalform, setModelform] = useState(modleObject);
  const [res, setres] = useState([]);
  const [footershow, setfootershow] = useState(false);

  function handlechange(e) {
    let tempObj = {};
    tempObj[e.target.id] = e.target.value.trim();
    setModelform({
      ...modalform,
      ...tempObj,
    });
  }
  function handleClick() {
    axios({
      method: "post",
      data: { modalform },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}advanceSearch`,
    }).then((res) => {
      setfootershow(true);
      setres(res.data);
    });
  }
  useEffect(() => {
    console.log(modalform);
  }, [modalform]);
  return (
    <div className="modal-container">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "20px" }}
          >
            Advance Search
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <input placeholder="CIN No." /> */}

          <div
            className="input-box"
            style={{ width: "100%", fontSize: "15px" }}
          >
            <div className="row">
              <div className="col-md-6">
                <div className="input-field">
                  <input
                    type="text"
                    className="input"
                    id="cin"
                    onChange={handlechange}
                    required
                  />

                  <label for="fname">CIN No.</label>
                </div>

                <div className="input-field">
                  <input
                    type="text"
                    className="input"
                    id="reagistration"
                    onChange={handlechange}
                    required
                  />

                  <label for="email">Year of Registration</label>
                </div>
                <div className="input-field">
                  <input
                    type="text"
                    className="input"
                    id="company_type"
                    onChange={handlechange}
                    required
                  />

                  <label for="phone">Company type</label>
                </div>
                <div className="input-field">
                  <input
                    type="text"
                    className="input"
                    id="city"
                    onChange={handlechange}
                    required
                  />
                  <label for="city">City</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-field">
                  <input
                    type="text"
                    className="input"
                    id="name"
                    onChange={handlechange}
                    required
                  />

                  <label for="lname">Name</label>
                </div>

                <div className="input-field">
                  <input
                    type="text"
                    className="input"
                    id="roc_location"
                    onChange={handlechange}
                    required
                  />

                  <label for="password">Roc Location</label>
                </div>
                <div className="input-field">
                  <input
                    type="text"
                    className="input"
                    id="category"
                    onChange={handlechange}
                    required
                  />

                  <label for="street">Category</label>
                </div>

                <div className="input-field">
                  <input
                    type="text"
                    className="input"
                    id="state"
                    onChange={handlechange}
                    required
                  />
                  <label for="state">State</label>
                </div>
              </div>
            </div>
            <div className="input-field">
              <button className="submit" onClick={handleClick}>
                Search
              </button>
            </div>

            {/* <div className="signin">
              <span>
                Already a member? <Link to="/login">Login</Link>
              </span>
            </div> */}
            {/* <div className="signin"></div>
            <br></br> */}
          </div>
          {/* <div className="input-field">
            <input type="text" className="input" id="CIN No." required />
            <label htmlFor="CIN">CIN No.</label>
          </div> */}
          {/* <input placeholder="Name" />
          <input placeholder="State" />
          <input placeholder="Company type" />
          <input placeholder="Date of Registration" />
          <input placeholder="Roc location" />
          <input placeholder="Category" />
          <input placeholder="Activity code" /> */}
        </Modal.Body>

        {footershow && (
          <div style={{ maxHeight: "150px", overflowY: "scroll" }}>
            {res.map((item) => (
              <Modal.Footer
                style={{
                  justifyContent: "center",
                  fontSize: "15px",
                  cursor: "pointer",
                }}
                onClick={() => handlengo(item.COMPANY_NAME)}
              >
                <Link
                  to={"/search"}
                  state={{ some: item.COMPANY_NAME }}
                  style={{ textDecoration: "none" }}
                >
                  {item.COMPANY_NAME}
                  <span>({item.CIN})</span>
                </Link>
              </Modal.Footer>
            ))}
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Home;

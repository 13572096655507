import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./TopProjects.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Topprojects = () => {
  const [apitopproject, setApitopproject] = useState([]);
  let state = useLocation();
  const navigation = useNavigate();
  const callapiTopproject = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setApitopproject(data.top_projects));
  };
  useEffect(() => {
    if ([null, ""].includes(state.state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      callapiTopproject(state.state.some);
    }
  }, []);
  return (
    <div>
      <Header />
      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link
            style={{ textDecoration: "none" }}
            to={"/search"}
            state={{
              some:
                state && state.state && state.state.some
                  ? state.state.some
                  : null,
            }}
          >
            {state && state.state && state.state.some ? state.state.some : null}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>board</Breadcrumb.Item>
      </Breadcrumb>
      <div className="topproject">
        <h1>Top Projects</h1>
        {apitopproject.map((item) => (
          <Card className="topproject-card">
            <Card.Img
              style={{ width: "40%", height: "300px" }}
              variant="top"
              src={item.photo}
            />
            <Card.Body style={{ width: "60%" }}>
              <Card.Title> {item.title}</Card.Title>
              <Card.Text>{item.about}</Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Topprojects;

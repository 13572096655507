import React, { useState } from "react";
export const DataContext = React.createContext();

const Context = (props) => {
  const ContextValues = {
    loginvalue: {},
  };
  const [contextState, setContextState] = useState(ContextValues);
  return (
    <>
      <DataContext.Provider value={{ contextState, setContextState }}>
        <div>{props.children}</div>
      </DataContext.Provider>
    </>
  );
};

export default Context;

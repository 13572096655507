import React, { useEffect, useRef } from "react";

const InactiveTimeout = ({ timeoutInMs, onTimeout }) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    const resetTimeout = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        onTimeout();
      }, timeoutInMs);
    };

    const handleActivity = () => {
      resetTimeout();
    };

    // Attach event listeners for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("scroll", handleActivity);

    // Start the timeout when the component mounts
    resetTimeout();

    // Clean up event listeners and the timeout when the component unmounts
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("scroll", handleActivity);
    };
  }, [timeoutInMs, onTimeout]);

  return null;
};

export default InactiveTimeout;

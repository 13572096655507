import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import "react-circular-progressbar/dist/styles.css";
import Card from "react-bootstrap/Card";
import { Alert, Button, ListGroup } from "react-bootstrap";
import "./Ngo.css";
// import charts from "FusionChartXT/js/fusioncharts.charts";
// import ReactFusioncharts from "FusionChartXT/integrations/react/js/react-fusioncharts";
// import ReactFC from "FusionChartXT/integrations/react/js/react-fusioncharts";
// import FusionCharts from "FusionChartXT/js/fusioncharts";
// import Pie2D from "FusionChartXT/js/fusioncharts.charts";

// import FusionTheme from "FusionChartXT/js/themes/fusioncharts.theme.fusion";
import ReactFusioncharts from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { FaSync } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "../../Roboto/Roboto-Black.ttf";

// charts(FusionCharts);

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const Ngo = () => {
  const temp = useParams();
  const percentage = 66;
  const [apidata, setApidata] = useState([]);

  let { state } = useLocation();
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Sync
    </Tooltip>
  );

  const callApi = () => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state.some}`)
      .then((res) => res.json())
      .then((data) => setApidata(data));
  };

  const handlesync = () => {
    axios({
      method: "post",
      data: { ngo: state.some, time: moment().format("DD-MM-YYYY HH:mm:ss") },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}sync`,
    }).then(function (response) {
      console.log(response);
    });
  };
  const navigation = useNavigate();
  useEffect(() => {
    if ([null, ""].includes(state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      callApi(state.some);
    }
  }, []);

  return (
    <div>
      <Header />
      <div className="main-container">
        <div className="ranking-container">
          <img
            style={{ width: 170, height: 170, borderRadius: "100px" }}
            src={apidata.logo}
          />

          <div className="ranking-subcontainer">
            <div className="sync-container">
              <h4 style={{ fontWeight: "800" }}>{apidata.company_name}</h4>
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <p
                  onClick={handlesync}
                  style={{
                    color: "grey",
                    cursor: "pointer",
                    fontSize: "small",
                  }}
                >
                  &nbsp;
                  <FaSync style={{ marginTop: "5px", marginLeft: "5px" }} />
                </p>
              </OverlayTrigger>
            </div>
            <p style={{ fontWeight: "500" }}>{apidata.cin}</p>
            <p>{apidata.estdate}</p>
            <div className="sync-date">
              {/* <FaSync style={{ color: "blue" }} /> */}
              Last synced @ {apidata.last_update}
            </div>
          </div>
        </div>

        <div className="about">
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Card.Title style={{ fontWeight: "700", color: "orange" }}>
                Vision
              </Card.Title>

              <Card.Text>{apidata.vision}</Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>

      <Details />
      <br></br>
      <MyComponent />
    </div>
  );
};
const Details = () => {
  let { state } = useLocation();
  const [Apidata, setApidata] = useState({});
  const handleactivity = () => {
    const userid = localStorage.getItem("userId");
    axios({
      method: "post",
      data: {
        userid: userid,
        visitedtime: moment().format("YYYY-MM-DD HH:mm:ss"),
        entity: state.some,
        pageUrl: `/${state.some}/promoters`,
      },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}activity`,
    }).then((res) => console.log(res));
  };
  const handleactivitymember = () => {
    const userid = localStorage.getItem("userId");
    axios({
      method: "post",
      data: {
        userid: userid,
        visitedtime: moment().format("YYYY-MM-DD HH:mm:ss"),
        entity: state.some,
        pageUrl: `/${state.some}/active_boardmembers`,
      },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}activity`,
    }).then((res) => console.log(res));
  };
  const presentInActivity = () => {
    const userid = localStorage.getItem("userId");
    axios({
      method: "post",
      data: {
        userid: userid,
        visitedtime: moment().format("YYYY-MM-DD HH:mm:ss"),
        entity: state.some,
        pageUrl: `/${state.some}/presentIn`,
      },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}activity`,
    }).then((res) => console.log(res));
  };
  const navigation = useNavigate();
  const api = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setApidata(data));
  };
  useEffect(() => {
    if ([null, ""].includes(state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      api(state.some);
    }
  }, []);
  return (
    <div>
      <div className="details">
        <Card className="ngo-card">
          <Card.Body onClick={handleactivity}>
            <Link
              to="/promoters"
              state={{ some: state && state.some ? state.some : null }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card.Title
                style={{ fontSize: "15px", fontWeight: "700", color: "orange" }}
              >
                Trustee
              </Card.Title>{" "}
              {Apidata.promoters &&
                Apidata.promoters
                  .filter((s, index) => {
                    return index <= 1;
                  })
                  .map((item) => (
                    <Card.Text style={{ marginBottom: "0.3rem" }}>
                      {item.name}
                    </Card.Text>
                  ))}
            </Link>
          </Card.Body>
          {/* <Card.Body>
            <Card.Title style={{ fontSize: "20px", fontWeight: "700", color: "orange" }}> Promoters</Card.Title>
           </Card.Body> */}
        </Card>

        <Card className="ngo-card">
          <Card.Body onClick={handleactivitymember}>
            <Link
              to="/board"
              state={{ some: state && state.some ? state.some : null }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card.Title
                style={{ fontSize: "15px", fontWeight: "700", color: "orange" }}
              >
                Management Team
              </Card.Title>

              {Apidata.board_members &&
                Apidata.board_members
                  .filter((item, index) => {
                    return index <= 1;
                  })
                  .map((item) => (
                    <Card.Text style={{ marginBottom: "0.3rem" }}>
                      {item.name}
                    </Card.Text>
                  ))}
            </Link>
          </Card.Body>
        </Card>

        <Card className="ngo-card">
          <Card.Body>
            <Link
              to="/topprojects"
              state={{ some: state && state.some ? state.some : null }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card.Title
                style={{ fontSize: "15px", fontWeight: "700", color: "orange" }}
              >
                Top Projects
              </Card.Title>
              {Apidata.top_projects &&
                Apidata.top_projects
                  .filter((item, index) => {
                    return index <= 1;
                  })
                  .map((item) => (
                    <Card.Text style={{ marginBottom: "0.3rem" }}>
                      {item.title}
                    </Card.Text>
                  ))}
            </Link>
          </Card.Body>
        </Card>

        <Card className="ngo-card">
          <Card.Body>
            <Link
              to=""
              state={{ some: state && state.some ? state.some : null }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card.Title
                style={{ fontSize: "15px", fontWeight: "700", color: "orange" }}
              >
                Focus Areas{" "}
              </Card.Title>
              {Apidata.focus_area &&
                Apidata.focus_area
                  .filter((item, index) => {
                    return index <= 1;
                  })
                  .map((item) => (
                    <Card.Text style={{ marginBottom: "0.3rem" }}>
                      {item}
                    </Card.Text>
                  ))}
            </Link>
          </Card.Body>
        </Card>

        <Card className="ngo-card">
          <Card.Body onClick={presentInActivity}>
            <Link
              to="/presentin"
              state={{ some: state && state.some ? state.some : null }}
              style={{ textDecoration: "none", color: "black" }}
            >
              <Card.Title
                style={{ fontSize: "15px", fontWeight: "700", color: "orange" }}
              >
                Present in
              </Card.Title>
              {Apidata.presentIn &&
                Apidata.presentIn
                  .filter((item, index) => {
                    return index <= 1;
                  })
                  .map((item) => (
                    <Card.Text style={{ marginBottom: "0.3rem" }}>
                      {item}
                    </Card.Text>
                  ))}
            </Link>
          </Card.Body>
        </Card>

        <Card className="ngo-card">
          <Card.Body>
            <Link
              to={Apidata.link}
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
            >
              <Card.Title
                style={{ fontSize: "15px", fontWeight: "700", color: "orange" }}
              >
                Contact Details
              </Card.Title>
              <Card.Text>{Apidata.ofc_address}</Card.Text>
            </Link>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

const MyComponent = () => {
  let { state } = useLocation();
  const [chartApi, setChartApi] = useState([]);
  const navigation = useNavigate();
  const api = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setChartApi(data));
  };
  useEffect(() => {
    if ([null, ""].includes(state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      api(state.some);
    }
  }, []);

  if (chartApi.incom) {
    var others = chartApi.incom.Other;
    var don = chartApi.incom.donations;
  }
  const dataSource = {
    chart: {
      caption: "Income",
      plottooltext: "<b>$percentValue</b> of Income from $label",
      showlegend: "1",
      showpercentvalues: "0",
      legendposition: "bottom",
      usedataplotcolorforlabels: "1",
      showBorder: "1",
      theme: "fusion",
      borderRadius: "11",
      baseFontSize: "10",
      valueFontSize: "10",
      captionFontSize: "14",
      outCnvBaseFontSize: "11",
      legendItemFontSize: "14",
      yAxisNameFontSize: "12",
      xAxisNameFontSize: "12",
      slicingDistance: "3",
    },

    data: [
      {
        label: "Donations",
        value: don,
      },
      {
        label: "Other",
        value: others,
      },
    ],
  };

  if (chartApi.incom) {
    var programme = chartApi.expenditure.programme;
    var others1 =
      parseInt(others) +
      parseInt(don) -
      parseInt(chartApi.expenditure.programme);
  }
  console.log(others1);
  const dataSource1 = {
    chart: {
      caption: "Expenditure",
      plottooltext: "<b>$percentValue</b> of expenditure on $label ",
      showlegend: "1",
      showpercentvalues: "0",
      legendposition: "bottom",
      usedataplotcolorforlabels: "1",
      showBorder: "1",
      theme: "fusion",
      borderRadius: "10",
      baseFontSize: "11",
      valueFontSize: "10",
      captionFontSize: "14",
      outCnvBaseFontSize: "11",
      legendItemFontSize: "14",
      yAxisNameFontSize: "12",
      xAxisNameFontSize: "12",
      slicingDistance: "3",
    },

    data: [
      {
        label: "Programme",
        value: programme,
      },
      {
        label: "others",
        value: others1,
      },
    ],
  };
  // if(chartApi.legal){

  //  }
  var crimnal = 0;
  var civil = 0;
  var consumer = 0;
  if (chartApi.legal) {
    chartApi.legal.map((item) => {
      if (item.category === "civil") {
        civil++;
      }
      if (item.category === "crimnal") {
        crimnal++;
      }
      if (item.category === "consumer") {
        consumer++;
      }
      return civil, crimnal, consumer;
    });
  }
  console.log(civil, crimnal, consumer);
  const dataSource2 = {
    chart: {
      caption: "Legal cases",
      plottooltext: "<b>$percentValue</b> of legal cases are $label cases",
      showlegend: "1",
      showpercentvalues: "0",
      legendposition: "bottom",
      usedataplotcolorforlabels: "1",
      showBorder: "1",
      theme: "fusion",
      borderRadius: "10",
      baseFontSize: "11",
      valueFontSize: "10",
      captionFontSize: "14",
      outCnvBaseFontSize: "11",
      legendItemFontSize: "14",
      yAxisNameFontSize: "12",
      xAxisNameFontSize: "12",
      slicingDistance: "3",
    },

    data: [
      {
        label: "civil ",
        value: civil,
      },
      {
        label: "crimnal ",
        value: crimnal,
      },
      {
        label: "consumer ",
        value: consumer,
      },
    ],
  };

  return (
    <div className="charts">
      <ReactFusioncharts
        type="pie2d"
        width="310"
        height="270"
        dataFormat="JSON"
        dataSource={dataSource}
      />
      <ReactFusioncharts
        type="pie2d"
        width="330"
        height="270"
        dataFormat="JSON"
        dataSource={dataSource1}
      />
      <ReactFusioncharts
        type="pie2d"
        width="310"
        height="270"
        dataFormat="JSON"
        dataSource={dataSource2}
      />

      <div className="charts-last">
        <Governance />
      </div>
    </div>
  );
};

const Governance = () => {
  let { state } = useLocation();
  const governanceClick = () => {
    let temp = JSON.parse(localStorage.getItem("user")) || [];
    localStorage.setItem(
      "user",
      JSON.stringify([...temp, "Governance and Management"])
    );
  };
  const socialClick = () => {
    let temp = JSON.parse(localStorage.getItem("user")) || [];
    localStorage.setItem("user", JSON.stringify([...temp, "Social Impact"]));
  };
  const reputationClick = () => {
    let temp = JSON.parse(localStorage.getItem("user")) || [];
    localStorage.setItem("user", JSON.stringify([...temp, "Reputation"]));
  };
  const religiousClick = () => {
    let temp = JSON.parse(localStorage.getItem("user")) || [];
    localStorage.setItem(
      "user",
      JSON.stringify([...temp, "Religious Affiliations"])
    );
  };
  const financialsClick = () => {
    let temp = JSON.parse(localStorage.getItem("user")) || [];
    localStorage.setItem("user", JSON.stringify([...temp, "Financials"]));
  };
  return (
    <div className="Governance">
      <Card style={{ width: "29rem" }}>
        <ListGroup variant="flush">
          <Link
            to="/management"
            state={{ some: state && state.some ? state.some : null }}
            style={{ textDecoration: "none" }}
            onClick={governanceClick}
          >
            <ListGroup.Item>
              <div className="governance-rating">
                <h6 style={{ marginTop: "0.5rem" }}>
                  Governance and Management
                </h6>
                {/* <div className="governance-subdiv">
                  <p>3.9</p>
                  <div className="box red"></div>
                </div> */}
              </div>
            </ListGroup.Item>
          </Link>
          <Link
            to="/social"
            state={{ some: state && state.some ? state.some : null }}
            style={{ textDecoration: "none" }}
            onClick={socialClick}
          >
            <ListGroup.Item>
              <div className="governance-rating">
                <h6 style={{ marginTop: "0.5rem" }}>Social Impact</h6>
                {/* <div className="governance-subdiv">
                  <p>7.9</p>
                  <div className="box green"></div>
                </div> */}
              </div>
            </ListGroup.Item>
          </Link>
          <Link
            to="/reputation"
            state={{ some: state && state.some ? state.some : null }}
            style={{ textDecoration: "none" }}
            onClick={reputationClick}
          >
            <ListGroup.Item>
              <div className="governance-rating">
                <h6 style={{ marginTop: "0.5rem" }}>Reputation</h6>
                {/* <div className="governance-subdiv">
                  <p>8.9</p>
                  <div className="box green"></div>
                </div> */}
              </div>
            </ListGroup.Item>
          </Link>
          <Link
            to="/religious"
            state={{ some: state && state.some ? state.some : null }}
            style={{ textDecoration: "none" }}
            onClick={religiousClick}
          >
            <ListGroup.Item>
              <div className="governance-rating">
                <h6 style={{ marginTop: "0.5rem" }}>Religious Affiliations</h6>
              </div>
            </ListGroup.Item>
          </Link>
          <Link
            to="/financial"
            state={{ some: state && state.some ? state.some : null }}
            style={{ textDecoration: "none" }}
            onClick={financialsClick}
          >
            <ListGroup.Item>
              <div className="governance-rating">
                <h6 style={{ marginTop: "0.5rem" }}>Financials</h6>
              </div>
            </ListGroup.Item>
          </Link>
        </ListGroup>
      </Card>
    </div>
  );
};

export default Ngo;

import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import sha256 from "crypto-js/sha256";
import Base64 from "crypto-js/enc-base64";

const Digilocker = () => {
   const randomToken = () => {
      // function randomToken() {
      var characters =
         "abcdefghiklmnopqrstuvwxyzABCDEFGHIKLMNOPQRSTUVWXYZ0123456789";

      var lenString = 64;
      var randomstring = "";

      //loop to select a new character in each iteration
      for (var i = 0; i < lenString; i++) {
         var rnum = Math.floor(Math.random() * characters.length);
         randomstring += characters.substring(rnum, rnum + 1);
      }
      return randomstring;
   };

   let code_verifier = randomToken();
   localStorage.setItem("cv", code_verifier);
   let code_challenge = getCodeChallenge(code_verifier);
   localStorage.setItem("cch", code_challenge);
   // let code_challenge = generateCodeChallenge(code_verifier);

   /* function generateCodeChallenge(codeVerifier) {
      const hashedVerifier = CryptoJS.SHA256(codeVerifier);
      const base64Encoded = CryptoJS.enc.Base64.stringify(hashedVerifier);
      const codeChallenge = base64Encoded
         .replace(/\+/g, "-")
         .replace(/\//g, "_")
         .replace(/=+$/, "");

      return codeChallenge;
   } */

   function getCodeChallenge(cv) {
      var hashed = sha256(cv);
      var base64encoded = base64urlencode(hashed);
      return base64encoded;
   }

   function base64urlencode(arg) {
      let s = Base64.stringify(arg);
      s = s.replace(/=/g, "");
      s = s.replace(/\+/g, "-");
      s = s.replace(/\//g, "_");

      return s;
   }

   console.log(code_verifier, code_challenge);

   return (
      <>
         <Link
            to={`https://digilocker.meripehchaan.gov.in/public/oauth2/1/authorize?response_type=code&client_id=VE4C4EF825&state=shivank&redirect_uri=https://kanatomy.com/callback&code_challenge=${code_challenge}&code_challenge_method=S256`}
         >
            Auth Link
         </Link>
         <br />
         code_verifier= {code_verifier}
         {/* <br />
         code_verifier2= {verifier} */}
         <br />
         code_challenge= {code_challenge}
         {/* <br />
         <Link
            to={`https://digilocker.meripehchaan.gov.in/public/oauth2/1/token?code=2c05fa77ff2e7a2b65b3d81ad21423cd8a2885bb&grant_type=&client_id=VE4C4EF825&client_secret=5182c08a8d31250f8850&redirect_uri=https:%2F%2Fkanatomy.com%2Fcallback&code_verifier=B9peUCYzbNgRW4rtD4db9fYvYrs5qmpMHkp468kAEQaEcMeAch`}
         >
            Get access token
         </Link> */}
      </>
   );
};

export default Digilocker;

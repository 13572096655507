import React, { useState } from 'react'
import Header from '../Header/Header'
import { ProgressBar } from 'react-bootstrap'

const Public = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
      setToggleState(index);
    };
  return (
    <div>
        <div>
    <Header/>
    <div className='Management'>
        
        <h5>Public Sentiments</h5>
        <div className='Management-container'>
        <p>3.9/10</p>
        <ProgressBar style={{width:'80%',margin:'5px'}} variant="success" now={40} />
        </div>


    </div>
  <div className='legal-tabs'>
  <div className="legal-container">
  <div className="bloc-tabs">
    <button
      className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
      onClick={() => toggleTab(1)}
    >
     <h6> Total Mentions</h6>
     <p>34</p>
        
    </button>
    <button
      className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
      onClick={() => toggleTab(2)}
    >
       <h6> Positive</h6>
     <p>34</p>
    </button>
    <button
      className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
      onClick={() => toggleTab(3)}
    >
      <h6> Neutral</h6>
     <p>34</p>
    </button>
    <button
      className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
      onClick={() => toggleTab(4)}
    >
      <h6> Negative</h6>
     <p>34</p>
    </button>
   
  </div>

  <div className="content-tabs">
    <div
      className={toggleState === 1 ? "content  active-content" : "content"}
    >
      <h5>Content 1</h5>
      <hr />
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
        praesentium incidunt quia aspernatur quasi quidem facilis quo nihil
        vel voluptatum?Lorem Ipsum is simply dummy text of the printing and typesetting industry.
         Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a 
         galley of type and scrambled it to make a type specimen book.
          It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. 
          It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
           and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
      </p>
    </div>

    <div
      className={toggleState === 2 ? "content  active-content" : "content"}
    >
      <h5>Content 2</h5>
      <hr />
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente
        voluptatum qui adipisci.
      </p>
    </div>

    <div
      className={toggleState === 3 ? "content  active-content" : "content"}
    >
      <h5>Content 3</h5>
      <hr />
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos sed
        nostrum rerum laudantium totam unde adipisci incidunt modi alias!
        Accusamus in quia odit aspernatur provident et ad vel distinctio
        recusandae totam quidem repudiandae omnis veritatis nostrum
        laboriosam architecto optio rem, dignissimos voluptatum beatae
        aperiam voluptatem atque. Beatae rerum dolores sunt.
      </p>
    </div>
    <div
      className={toggleState === 4 ? "content  active-content" : "content"}
    >
      <h5>Content 4</h5>
      <hr />
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos sed
        nostrum rerum laudantium totam unde adipisci incidunt modi alias!
        Accusamus in quia odit aspernatur provident et ad vel distinctio
        recusandae totam quidem repudiandae omnis veritatis nostrum
        laboriosam architecto optio rem, dignissimos voluptatum beatae
        aperiam voluptatem atque. Beatae rerum dolores sunt.
      </p>
    </div>
   
   
  </div>
</div>
</div>
</div>
    </div>
  )
}

export default Public
import React from "react";
import Header from "../Header/Header";
import "./Promoters.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

const Promoters = () => {
  const navigation = useNavigate();
  const [promoterdata, setPromoterdata] = useState([]);

  const apipromotes = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setPromoterdata(data.promoters));
  };
  let state = useLocation();
  useEffect(() => {
    console.log(state);

    if ([null, ""].includes(state.state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      apipromotes(state.state.some);
    }
  }, []);
  return (
    <div>
      <Header />
      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link
            style={{ textDecoration: "none" }}
            to={"/search"}
            state={{
              some:
                state && state.state && state.state.some
                  ? state.state.some
                  : null,
            }}
          >
            {state && state.state && state.state.some ? state.state.some : null}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Trustee</Breadcrumb.Item>
      </Breadcrumb>
      <div class="responsive-container-block promoterscontainer">
        <p class="text-blk team-head-text">Trustee</p>
        <div class="responsive-container-block">
          {promoterdata && promoterdata.length > 0
            ? promoterdata.map((item) => (
                <div class="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 promoters-container">
                  <div class="promoters">
                    <div class="team-image-wrapper">
                      <img class="team-member-image" src={item.photo} />
                    </div>
                    <p class="text-blk name">{item.name}</p>
                    <p class="text-blk position">{item.designation}</p>
                    <p class="text-blk feature-text">{item.about}</p>
                    <div class="social-icons">
                      <a href="https://www.twitter.com" target="_blank">
                        <img
                          class="twitter-icon"
                          src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/Icon.svg"
                        />
                      </a>
                      <a href="https://www.facebook.com" target="_blank">
                        <img
                          class="facebook-icon"
                          src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/Icon-1.svg"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default Promoters;

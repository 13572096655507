import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "./Otp.css";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../Context";
import CryptoJS from "crypto-js";

const Otp = () => {
  const navigation = useNavigate();
  const [otpchng, setotpchng] = useState(0);
  const [errotp, setErrotp] = useState("");
  const [timer, setTimer] = useState(120);
  const [resend, setresend] = useState(false);
  const localContext = useContext(DataContext);
  const randomToken = localStorage.getItem("token");
  let countdown;

  const handleValidate = () => {
    const sessionToken = localStorage.getItem("token");
    console.log(sessionToken);
    axios({
      method: "post",
      data: { validate: otpchng, sessionToken: sessionToken },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}otp`,
    }).then(function (res) {
      if (res.data.error) {
        navigation("/home");
      } else {
        setErrotp(res.data.errormsg);
      }
    });
  };
  const handleresend = () => {
    setresend(false);
    setTimer(120);
    countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(countdown);
          return 0;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);

    var tokken = randomToken;
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(localContext.contextState.loginvalue),
      tokken
    ).toString();
    axios({
      method: "post",
      data: { dat: ciphertext },
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",

        Authorization: tokken,
      },
      url: `${process.env.REACT_APP_PUBLIC_URL}login_1`,
    }).then(function (res) {
      // setApiloginmatch(res.data);
      console.log(res);
    });
  };

  useEffect(() => {
    countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(countdown);
          return 0;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
    console.log("context", localContext.contextState);
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Some browsers require a return value to display the dialog
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearInterval(countdown);
    };
    //return () => clearInterval(countdown);
  }, []);
  useEffect(() => {
    if (timer === 0) {
      // Timer expired, handle it accordingly (e.g., show a message, disable submit button, etc.)
      // clearInterval(countdown);
      setresend(true);
    }
  }, [timer]);
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="wrapper">
      <div className="container main">
        <div
          className="row"
          style={{
            width: "900px",
            height: "450px",
            borderRadius: "10px",
            background: "#fff",
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.2)",
          }}
        >
          <div className="col-md-6 side-image"></div>
          <div className="col-md-6 right">
            <div className="input-box">
              <header>Please Enter the One Time Password</header>
              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="otp"
                  onChange={(e) => setotpchng(e.target.value)}
                  required
                />
                <label htmlFor="otp">OTP</label>
              </div>
              <div style={{ color: "red" }}>{errotp}</div>

              {resend ? (
                <Link onClick={handleresend}>Resend</Link>
              ) : (
                <p>Time remaining: {formatTime(timer)}</p>
              )}

              <div className="input-field">
                <button className="submit" onClick={handleValidate}>
                  Validate
                </button>
              </div>
              <div className="signin"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;

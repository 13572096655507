import React, { useState } from "react";
import Header from "../Header/Header";
import { ProgressBar } from "react-bootstrap";
import "./Legal.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";

const Legal = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div>
      <Header />
      <Breadcrumb style={{margin:'10px'}}>
        <Breadcrumb.Item><Link to={'search'}>Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item active>board</Breadcrumb.Item>
      </Breadcrumb>
      <div className="Management">
        <h5>Legal Issues</h5>
        <div className="Management-container">
          <p>3.9/10</p>
          <ProgressBar
            style={{ width: "80%", margin: "5px" }}
            variant="success"
            now={40}
          />
        </div>
      </div>
      <div className="legal-tabs">
        <div className="legal-container">
          <div className="bloc-tabs">
            <button
              className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(1)}
            >
              <h6> Total Cases</h6>
              <p>03</p>
            </button>
            <button
              className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(2)}
            >
              <h6> Pending Cases</h6>
              <p>00</p>
            </button>
            <button
              className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(3)}
            >
              <h6> Disposed Cases</h6>
              <p>03</p>
            </button>
            <button
              className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(4)}
            >
              <h6> Civil Cases</h6>
              <p>00</p>
            </button>
            <button
              className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(5)}
            >
              <h6> Criminal Cases</h6>
              <p>03</p>
            </button>
            <button
              className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(6)}
            >
              <h6> Consumer Affairs</h6>
              <p>00</p>
            </button>
            <button
              className={toggleState === 7 ? "tabs active-tabs" : "tabs"}
              onClick={() => toggleTab(7)}
            >
              <h6>FIR</h6>
              <p>08</p>
            </button>
          </div>

          <div className="content-tabs">
            <div
              className={
                toggleState === 1 ? "content  active-content" : "content"
              }
            >
              <h5>Total Cases</h5>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>Name: VIKAS YADAV Vs STATE OF U.P. AND ORS. ETC. ETC. -</p>
                <p>Judge_name: DIPAK MISRA,C. NAGAPPAN</p>
                <p>case_number: CRIMINAL APPEAL/1531/2015</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Date_of_decision: 03-10-2016</p>
                <p> Disposal_nature: Disposed off</p>
                <p>Offence:</p>
                </div>
              </div>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>Name: SANTOSH KUMAR SATISHBHUSHAN BARIYAR Vs STATE OF MAHARASHTRA -</p>
                <p>Judge_name: S.B. SINHA,CYRIAC JOSEPH</p>
                <p>case_number: CRIMINAL APPEAL/1478/2005</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Date_of_decision: 13-05-2009</p>
                <p> Disposal_nature: Dismissed</p>
                <p>Offence:</p>
                </div>
              </div>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>Name: SWAMY SHRADDANANDA @ MURALI MANOHAR  MISHRA Vs STATE OF KARNATAKA -</p>
                <p>Judge_name: B.N. AGRAWAL,G.S. SINGHVI,AFTAB ALAM</p>
                <p>case_number: CRIMINAL APPEAL/454/2006</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Date_of_decision: 22-07-2008</p>
                <p> Disposal_nature: Disposed off</p>
                <p>Offence:</p>
                </div>
              </div>
            </div>

            <div
              className={
                toggleState === 2 ? "content  active-content" : "content"
              }
            >
              <h5>Content 2</h5>
              <hr />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Sapiente voluptatum qui adipisci.
              </p>
            </div>

            <div
              className={
                toggleState === 3 ? "content  active-content" : "content"
              }
            >
               <h5> Disposed Cases</h5>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>Name: VIKAS YADAV Vs STATE OF U.P. AND ORS. ETC. ETC. -</p>
                <p>Judge_name: DIPAK MISRA,C. NAGAPPAN</p>
                <p>case_number: CRIMINAL APPEAL/1531/2015</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Date_of_decision: 03-10-2016</p>
                <p> Disposal_nature: Disposed off</p>
                <p>Offence:</p>
                </div>
              </div>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>Name: SANTOSH KUMAR SATISHBHUSHAN BARIYAR Vs STATE OF MAHARASHTRA -</p>
                <p>Judge_name: S.B. SINHA,CYRIAC JOSEPH</p>
                <p>case_number: CRIMINAL APPEAL/1478/2005</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Date_of_decision: 13-05-2009</p>
                <p> Disposal_nature: Dismissed</p>
                <p>Offence:</p>
                </div>
              </div>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>Name: SWAMY SHRADDANANDA @ MURALI MANOHAR  MISHRA Vs STATE OF KARNATAKA -</p>
                <p>Judge_name: B.N. AGRAWAL,G.S. SINGHVI,AFTAB ALAM</p>
                <p>case_number: CRIMINAL APPEAL/454/2006</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Date_of_decision: 22-07-2008</p>
                <p> Disposal_nature: Disposed off</p>
                <p>Offence:</p>
                </div>
              </div>
            </div>
            <div
              className={
                toggleState === 4 ? "content  active-content" : "content"
              }
            >
              <h5>Content 4</h5>
              <hr />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                sed nostrum rerum laudantium totam unde adipisci incidunt modi
                alias! Accusamus in quia odit aspernatur provident et ad vel
                distinctio recusandae totam quidem repudiandae omnis veritatis
                nostrum laboriosam architecto optio rem, dignissimos voluptatum
                beatae aperiam voluptatem atque. Beatae rerum dolores sunt.
              </p>
            </div>
            <div
              className={
                toggleState === 5 ? "content  active-content" : "content"
              }
            >
              <h5>Criminal Cases</h5>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>Name: VIKAS YADAV Vs STATE OF U.P. AND ORS. ETC. ETC. -</p>
                <p>Judge_name: DIPAK MISRA,C. NAGAPPAN</p>
                <p>case_number: CRIMINAL APPEAL/1531/2015</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Date_of_decision: 03-10-2016</p>
                <p> Disposal_nature: Disposed off</p>
                <p>Offence:</p>
                </div>
              </div>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>Name: SANTOSH KUMAR SATISHBHUSHAN BARIYAR Vs STATE OF MAHARASHTRA -</p>
                <p>Judge_name: S.B. SINHA,CYRIAC JOSEPH</p>
                <p>case_number: CRIMINAL APPEAL/1478/2005</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Date_of_decision: 13-05-2009</p>
                <p> Disposal_nature: Dismissed</p>
                <p>Offence:</p>
                </div>
              </div>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>Name: SWAMY SHRADDANANDA @ MURALI MANOHAR  MISHRA Vs STATE OF KARNATAKA -</p>
                <p>Judge_name: B.N. AGRAWAL,G.S. SINGHVI,AFTAB ALAM</p>
                <p>case_number: CRIMINAL APPEAL/454/2006</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Date_of_decision: 22-07-2008</p>
                <p> Disposal_nature: Disposed off</p>
                <p>Offence:</p>
                </div>
              </div>
            </div>
            <div
              className={
                toggleState === 6 ? "content  active-content" : "content"
              }
            >
              <h5>Content 6</h5>
              <hr />
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                sed nostrum rerum laudantium totam unde adipisci incidunt modi
                alias! Accusamus in quia odit aspernatur provident et ad vel
                distinctio recusandae totam quidem repudiandae omnis veritatis
                nostrum laboriosam architecto optio rem, dignissimos voluptatum
                beatae aperiam voluptatem atque. Beatae rerum dolores sunt.
              </p>
            </div>
            <div
              className={
                toggleState === 7 ? "content  active-content" : "content"
              }
            >
              <h5>FIR</h5>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>FIR_No: 99/17</p>
                <p>FIR_Date: 20-06-2017 00:00:00</p>
                <p>Complainant: pankaj kumar gupta s/o rajeswer prasad gupta</p>
                <p>Address: vill-stastion road sarai ps-sarai dist-vsl</p>
                <p>Accused: ""</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Police_Station: ""</p>
                <p> Sections: 448/384/506/34 i.p.c  & 27 arms  act</p>
                <p>District": "Vaishali</p>
                <p>Incident_Date: 2017-06-19 00:00:00.0000000</p>
                <p>Status: ""</p>
                </div>
              </div>
              <hr />
              <div className="oxfam-total">
                <div className="oxfam-tot-sub">
                <p>FIR_No: 07/17</p>
                <p>FIR_Date: 06-01-2017 15:29:04</p>
                <p>Complainant: LAKCHHMI NARAYAN GUPTA S/0 BAGESHWER PARSAD</p>
                <p>Address: VIIL+PS- SASTRI NAGAR NIKAR DNS DIST- PATANA</p>
                <p>Accused: ""</p>
                </div>
                <div  className="oxfam-tot-sub">
                <p>Police_Station: "Sarai PS"</p>
                <p> Sections:135 ELECTRIC ACT</p>
                <p>District": "Vaishali</p>
                <p>Incident_Date: 2016-11-28 00:00:00.0000000"</p>
                <p>Status: ""</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legal;

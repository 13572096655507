import React from "react";
import DatamapsIndia from "react-datamaps-india";
import Header from "../Header/Header";
import "./PresentIn.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import ReactFC from "FusionChartXT/integrations/react/js/react-fusioncharts";
// import FusionCharts from "FusionChartXT/js/fusioncharts";
// import Maps from "FusionChartXT/js/fusioncharts.maps";
// import FusionTheme from "FusionChartXT/js/themes/fusioncharts.theme.fusion";
// import India from "FusionChartXT/maps/fusioncharts.india";
// import China2 from "FusionChartXT/maps/fusioncharts.china2";
// import Card from "react-bootstrap/Card";
// import CardGroup from "react-bootstrap/CardGroup";
import { useState } from "react";
import { useEffect } from "react";

//import GoogleMapReact from "google-map-react";
import GoogleMapReact from "google-map-react";

// ReactFC.fcRoot(FusionCharts, Maps, India, China2, FusionTheme);
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const PresentIn = () => {
  let state = useLocation();
  const navigation = useNavigate();
  const [mapstate, setmapstate] = useState("");
  const [apimap, setapimap] = useState({});
  const apipresent = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setapimap(data));
  };
  useEffect(() => {
    if ([null, ""].includes(state.state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      apipresent(state.state.some);
    }
  }, []);
  function handlemap(item) {
    setmapstate(item);
  }
  console.log(mapstate);

  const s = "005";
  const defaultCenter = {
    lat: 23.255135196452333, // Default latitude
    lng: 70.76228597520875, // Default longitude
  };
  //14.776655614725602, 75.77737696830141
  const locations = [
    { lat: 23.255135196452333, lng: 70.76228597520875, title: "Location 1" },
    { lat: 25.741538644123185, lng: 86.13205475179089, title: "Location 2" },
    { lat: 26.538459692752305, lng: 92.64890426767887, title: "Location 3" },
    { lat: 22.201121566667748, lng: 82.07343715370459, title: "Location 4" },
    { lat: 23.74807157377684, lng: 85.16505214894867, title: "Location 5" },
    { lat: 14.776655614725602, lng: 75.77737696830141, title: "Location 6" },
  ];

  const handleApiLoaded = (map, maps) => {
    locations.forEach((location) => {
      const marker = new maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: map,
        title: location.title,
      });
    });
  };

  return (
    <div>
      <Header />
      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link
            style={{ textDecoration: "none" }}
            to={"/search"}
            state={{
              some:
                state && state.state && state.state.some
                  ? state.state.some
                  : null,
            }}
          >
            {state && state.state && state.state.some ? state.state.some : null}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>board</Breadcrumb.Item>
      </Breadcrumb>
      <div className="present-container">
        <div style={{ marginLeft: "60px" }}>
          <div style={{ height: "70vh", width: "650px" }}>
            {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA_zoh5u9v9g2QC492NMZ7XFXQDo6yV7IM",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              19.20247358413474, 72.8526518808515
              <AnyReactComponent
                lat={19.20247358413474}
                lng={72.8526518808515}
                text="My Marker"
              />
            </GoogleMapReact> */}
            {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA_zoh5u9v9g2QC492NMZ7XFXQDo6yV7IM",
              }} // Replace with your API key
              defaultCenter={defaultCenter}
              defaultZoom={5}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              https://maps.google.com/maps?width=600&height=400&hl=en&q=Gujarat&t=&z=5&ie=UTF8&iwloc=B&output=embed
            /> */}
            <div className="gmap_canvas">
              {mapstate ? (
                <iframe
                  style={{ width: "100%", height: "500px" }}
                  className="gmap_iframe"
                  frameborder="1"
                  scrolling="no"
                  src={`https://maps.google.com/maps?width=600&height=400&hl=en&q=${mapstate}&t=&z=4&ie=UTF8&iwloc=B&output=embed`}
                ></iframe>
              ) : (
                <iframe
                  style={{ width: "100%", height: "500px" }}
                  className="gmap_iframe"
                  frameborder="1"
                  scrolling="no"
                  src={`https://maps.google.com/maps?width=600&height=400&hl=en&q=India&t=&z=4&ie=UTF8&iwloc=B&output=embed`}
                ></iframe>
              )}
            </div>
          </div>
        </div>
        <div className="state-container">
          <br></br>
          <p class="text-blk team-head-text"> Present In </p>
          <div className="state-subcontainer">
            {apimap.presentIn &&
              apimap.presentIn.map((item) => (
                <div className="state" onClick={() => handlemap(item)}>
                  {item}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentIn;

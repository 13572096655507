import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../Image/logo.png";
import "./firstpage.css";
import { useEffect } from "react";
import axios from "axios";

const Firstpage = () => {
  const navigate = useNavigate();
  // const [protectedstate, setprotectedstate] = useState("");
  const handleRegister = () => {
    navigate("/register");
  };
  const handleLogin = () => {
    navigate("/login");
  };
  const navigation = useNavigate();
  useEffect(() => {
    let userid = localStorage.getItem("userId");
    axios({
      method: "post",
      data: { userId: userid },
      headers: { "Content-Type": "application/json" },
      url: "https://react.kanalytics.in/node_k/protected",
    }).then((res) => {
      if (res.data.session !== "") {
        navigation("/home");
      }
    });
  }, []);
  return (
    // <div className='firstpage-container'>
    //    <img src={logo} alt='Firstpage-logo' height={150}/>
    //   <div className='firstpage-button'>
    //   <button className="btn btn-outline-primary" onClick={handleRegister}>Register</button>
    //   <button className="btn btn-outline-primary" onClick={handleLogin}>Login</button>
    //   </div>
    // </div>
    <div className="wrapper">
      <div className="container main">
        <div
          className="row"
          style={{
            width: "600px",
            height: "350px",
            borderRadius: "10px",
            background: "#fff",
            boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.2)",
          }}
        >
          <div className="col-md-12 right">
            <div className="firstpage-container">
              <img src={logo} alt="Firstpage-logo" height={100} />
              <div className="firstpage-button">
                <button
                  className="btn btn-outline-primary btn-reg"
                  onClick={handleRegister}
                >
                  Register
                </button>
                <button
                  className="btn btn-outline-primary btn-reg"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Firstpage;

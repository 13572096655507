import React, { useEffect } from "react";
import Header from "../Header/Header";
import Card from "react-bootstrap/Card";
import "./About.css";

export const About = () => {
//   useEffect(()=>{
//     let temp=JSON.parse(localStorage.getItem('user')) || [];
//      localStorage.setItem('user',JSON.stringify([...temp,"about"]))
//  },[])
  return (
    <div>
      <Header />

      <div className="about-container">
        <Card style={{ width: "95%", border: "0" }}>
          <Card.Header as="h5" style={{ backgroundColor: "white" }}>
            About the Organization
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ut
              mauris elit. Phasellus molestie fermentum purus eu condimentum.
              Nullam vel leo mollis neque rhoncus suscipit at nec diam. Nam
              laoreet placerat suscipit. Nulla lectus neque, suscipit in augue
              lobortis, pulvinar eleifend nunc. Maecenas volutpat lectus a
              efficitur iaculis. In vel lacinia justo, at cursus arcu. Ut
              finibus laoreet quam, at rhoncus tellus iaculis non.
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="about-container">
        <Card style={{ width: "95%", border: "0" }}>
          <Card.Header as="h5" style={{ backgroundColor: "white" }}>
          Company Details
          </Card.Header>
          <Card.Body>
            <Card.Text>Name: Cry</Card.Text>
            <Card.Text>CIN: L28362MH973932PL3832</Card.Text>
            <Card.Text>Status: Active</Card.Text>
            <Card.Text>Date of Incorporation: 09/02/1998</Card.Text>
            <Card.Text>Date of Balance Sheet: 02/03/2019</Card.Text>
            <Card.Text>Last Date of AGM: 02/03/2019</Card.Text>
            <Card.Text>Active Compliance: ACTIVE</Card.Text>
            <Card.Text>Category: NGO</Card.Text>
            <Card.Text>Class: </Card.Text>
            <Card.Text>Listing Status: </Card.Text>
            <Card.Text>ROC Code:</Card.Text>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

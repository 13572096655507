import React, { useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

const Callback = () => {
   const [acctoken, setacctoken] = useState();
   const location = useLocation();
   let callback_code = location.search.split("&")[0].split("=")[1];
   let code_verifier = localStorage.getItem("cv");

   const digilockeraccessToken = () => {
      /* axios({
         method: "post",
         url: "https://digilocker.meripehchaan.gov.in/public/oauth2/2/token",
         headers: {
            ContentType: "application/x-www-form-urlencoded",
         },
         body: {
            code: callback_code,
            grant_type: "authorization_code",
            client_id: "VE4C4EF825",
            client_secret: "5182c08a8d31250f8850",
            redirect_uri: "https://kanatomy.com/callback",
            code_verifier: code_verifier,
         },
      }).then(function (response) {
         console.log(response.data);
         setacctoken(response.data);
      }); */
      axios
         .post(
            "https://digilocker.meripehchaan.gov.in/public/oauth2/2/token",
            {
               code: callback_code,
               grant_type: "authorization_code",
               client_id: "VE4C4EF825",
               client_secret: "5182c08a8d31250f8850",
               redirect_uri: "https://kanatomy.com/callback",
               code_verifier: code_verifier,
            },
            {
               "Access-Control-Allow-Origin": "*",
               "Content-Type": "application/x-www-form-urlencoded",
            }
         )
         .then(function (response) {
            console.log(response.data);
            setacctoken(response.data);
         });
   };
   return (
      <>
         <div>Callback</div>

         <br />
         <button onClick={(e) => digilockeraccessToken()}>
            Get access token
         </button>
         <br />
         <div>
            Response
            <br />
            {JSON.stringify(acctoken)}
         </div>
      </>
   );
};

export default Callback;

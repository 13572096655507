import React from "react";
import Header from "../Header/Header";
import ProgressBar from "react-bootstrap/ProgressBar";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";

const CatDiv = ({ main, findCat }) => {
  let [res, setRes] = useState([]);
  console.log(main, findCat);

  useEffect(() => {
    let res0 = main.find((cat) => {
      return cat._id === findCat;
    });
    setRes(res0);
  }, []);

  console.log(res && res.art);

  return (
    <div>
      {res && res.art ? (
        res.art.map((items) => (
          <>
            <div className="political">
              <div style={{ width: "20%" }}>{items.headline}</div>
              <Link target="_blank" to={items.link}>
                {items.source}
              </Link>
              <div>{moment(items.date).format("DD-MM-YYYY")}</div>
            </div>
            <hr />
          </>
        ))
      ) : (
        <div>No data</div>
      )}
    </div>
  );
};

const Reputation = () => {
  let state = useLocation();
  const navigation = useNavigate();
  const [apimanagment, setApimanagment] = useState();

  if (apimanagment) {
    var s = apimanagment.total;
  }
  const apireputation = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setApimanagment(data.analyse));
  };
  useEffect(() => {
    if ([null, ""].includes(state.state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      apireputation(state.state.some);
    }
  }, []);
  return (
    <div>
      <Header />
      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link
            style={{ textDecoration: "none" }}
            to={"/search"}
            state={{
              some:
                state && state.state && state.state.some
                  ? state.state.some
                  : null,
            }}
          >
            {state && state.state && state.state.some ? state.state.some : null}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Reputation</Breadcrumb.Item>
      </Breadcrumb>
      <div className="Management">
        <h5>Reputation</h5>
        <div className="Management-container"></div>
      </div>
      <div className="Management-accordion">
        <Accordion style={{ width: "95%" }} defaultActiveKey={["0"]} alwaysOpen>
          {/* {apimanagment &&
            apimanagment.category.map((item) =>
              item._id === "Controversies" ? (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Controversies
                    <Badge style={{ marginLeft: "10px" }}>
                      {((item.artcnt / s) * 100).toFixed(2)}%
                    </Badge>
                  </Accordion.Header>
                  <Accordion.Body
                    style={{ height: "200px", overflowY: "scroll" }}
                  >
                    {item.art.map((items) => (
                      <>
                        <div className="political">
                          <div style={{ width: "20%" }}>{items.headline}</div>
                          <Link to={items.link}>{items.source}</Link>
                          <div>{moment(items.date).format("DD-MM-YYYY")}</div>
                        </div>
                        <hr />
                      </>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    Controversies
                    <Badge style={{ marginLeft: "10px" }}>0%</Badge>
                  </Accordion.Header>
                  <Accordion.Body>
                    <h6>No data found</h6>
                  </Accordion.Body>
                </Accordion.Item>
              )
            )} */}
          {apimanagment && apimanagment.category && (
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Controversies
                {apimanagment.category.map(
                  (item) =>
                    item._id === "Controversies" && (
                      <Badge style={{ marginLeft: "10px" }}>
                        {((item.artcnt / s) * 100).toFixed(2)}%
                      </Badge>
                    )
                )}
              </Accordion.Header>
              <Accordion.Body
                style={{ maxHeight: "100px", overflowY: "scroll" }}
              >
                <CatDiv main={apimanagment.category} findCat="Controversies" />
              </Accordion.Body>
            </Accordion.Item>
          )}

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Media Presence
              <Badge style={{ marginLeft: "10px" }}>10/10</Badge>
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Reputation;

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Image/logo.png";
import axios from "axios";
import createActivityDetector from "activity-detector";
import { useEffect, useState } from "react";
import InactiveTimeout from "../Idle-timer/ReactIdelTimer";
import moment from "moment";

function Header() {
  const navigation = useNavigate();
  const handleInactiveTimeout = () => {
    // Perform logout or other actions here
    const userId = localStorage.getItem("userId");
    const session = localStorage.getItem("token");
    axios({
      method: "post",
      data: { userId: userId, tokken: session },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}logout`,
    }).then(localStorage.clear());
    console.log("User has been inactive for too long. Logging out...");
  };

  const state = useLocation();
  const homeClick = () => {
    let temp = JSON.parse(localStorage.getItem("user")) || [];
    localStorage.setItem("user", JSON.stringify([...temp, "ngo-home"]));
  };

  const aboutClick = () => {
    let temp = JSON.parse(localStorage.getItem("user")) || [];
    localStorage.setItem("user", JSON.stringify([...temp, "about"]));
  };
  const logoutClick = () => {
    const userId = localStorage.getItem("userId");
    const session = localStorage.getItem("token");
    axios({
      method: "post",
      data: { userId: userId, tokken: session },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}logout`,
    }).then(localStorage.clear());
  };
  const mediaClick = () => {
    let temp = JSON.parse(localStorage.getItem("user")) || [];
    localStorage.setItem("user", JSON.stringify([...temp, "Media Sentiments"]));
  };
  const publicClick = () => {
    let temp = JSON.parse(localStorage.getItem("user")) || [];
    localStorage.setItem(
      "user",
      JSON.stringify([...temp, "Public Sentiments"])
    );
  };
  useEffect(() => {
    const time = localStorage.getItem("time");
    const twoMinutesAgo = moment().subtract(120, "minutes");
    if (moment(time).isBefore(twoMinutesAgo)) {
      console.log("mhi khulna chahiye");
      const userId = localStorage.getItem("userId");
      const session = localStorage.getItem("token");
      axios({
        method: "post",
        data: { userId: userId, tokken: session },
        headers: { "Content-Type": "application/json" },
        url: `${process.env.REACT_APP_PUBLIC_URL}logout`,
      }).then(localStorage.clear());
    }
  }, []);
  return (
    <>
      <InactiveTimeout
        timeoutInMs={7200000}
        onTimeout={handleInactiveTimeout}
      />
      <Navbar bg="light" expand="lg">
        <Container style={{ maxWidth: "100%" }}>
          <Navbar.Brand href="#home">
            <Link to="/home">
              {" "}
              <img
                src={logo}
                className="d-inline-block align-top"
                alt=" logo"
                height={60}
                width={200}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link style={{ color: "orange" }}>
                Hello! {localStorage.getItem("name")}
              </Nav.Link>
              {state.state !== null ? (
                <Nav.Link>
                  <Link
                    to="/home"
                    style={{ textDecoration: "none", color: "grey" }}
                  >
                    Home
                  </Link>
                </Nav.Link>
              ) : (
                ""
              )}

              <Nav.Link>
                <Link
                  to="/login"
                  style={{ textDecoration: "none", color: "grey" }}
                  onClick={logoutClick}
                >
                  Logout
                </Link>
              </Nav.Link>
              {/*<Nav.Link><Link to='/media' style={{ textDecoration: 'none' ,color:'grey' }} onClick={mediaClick}>Media</Link></Nav.Link>
            <Nav.Link><Link to='/public' style={{ textDecoration: 'none' ,color:'grey' }} onClick={publicClick}>Public</Link></Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;

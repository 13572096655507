import axios from "axios";
import React, { useEffect, useState } from "react";

import { Navigate } from "react-router-dom";

const Protected = ({ children }) => {
  const [protectedstate, setprotectedstate] = useState("");
  const [loading, setloading] = useState(false);
  let userid = localStorage.getItem("userId");
  useEffect(() => {
    axios({
      method: "post",
      data: { userId: userid },
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}protected`,
    }).then((res) => {
      setprotectedstate(res.data.session);
      setloading(true);
    });
  });
  if (loading) {
    return protectedstate !== "" ? children : <Navigate to="/" replace />;
  }
};

export default Protected;

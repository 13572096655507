import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Signup.css";
import Pic from "./signupImage.png";
import logo from "../Image/logo.png";

function Signup() {
  const navigation = useNavigate();
  const formobj = {
    fname: "",
    lname: "",
    email: "",
    password: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    pan: "",
    adhaar: "",
    login_at: "",
    session: "",
    active: false,
  };
  const [formstate, setformState] = useState(formobj);
  const [formerror, setFormerror] = useState({});

  const handlechange = (e) => {
    console.log(e.target.id, e.target.value);
    setformState({ ...formstate, [e.target.id]: e.target.value });
  };
  const handleSubmit = () => {
    const done = validation();
    if (done) {
      navigation("/login");
      callApi();
    }
  };
  const callApi = () => {
    console.log(formstate);
    axios({
      method: "POST",
      data: formstate,
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_PUBLIC_URL}user`,
    }).then(function (response) {
      console.log(response);
    });
  };
  const validation = () => {
    const errorobj = {};
    //var emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    var phonePattern = /[789][0-9]{9}/;
    var panPattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    var adhaarPattern =
      /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
    if (formstate.fname === "") {
      errorobj.fname = "Name is empty";
    }

    if (formstate.email === "") {
      errorobj.email = "email is empty";
    }
    if (formstate.password === "") {
      errorobj.password = "password is empty";
    }
    // if (!emailPattern.test(formstate.email)) {
    //   errorobj.email = "Email Format is incorrect";
    // }
    if (!phonePattern.test(formstate.phone)) {
      errorobj.phone = "phone pattern is incorrect";
    }
    if (!panPattern.test(formstate.pan)) {
      errorobj.pan = "Pan no. is incorrect";
    }
    if (!adhaarPattern.test(formstate.adhaar)) {
      errorobj.adhaar = "Aadhaar no. is incorrect";
    }

    setFormerror(errorobj);

    if (Object.keys(errorobj).length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div class="wrapper" style={{}}>
      <div style={{ width: "90%", marginLeft: "20px", marginBottom: "10px" }}>
        <img src={logo} height={60} />
      </div>
      <div
        class="inner"
        style={{
          height: "600px",
          borderRadius: "10px",
          background: "#fff",
          boxShadow: "5px 5px 10px 1px rgba(0,0,0,0.2)",
        }}
      >
        <div class="image-holder">
          <img src={Pic} alt="Signup-image" height="550px" />
        </div>

        <div className="input-box">
          <br></br>
          <header>Register Account</header>
          <div className="row">
            <div className="col-md-6">
              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="fname"
                  onChange={handlechange}
                  required
                />
                {formerror.name}
                <label for="fname">First Name</label>
              </div>

              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="email"
                  onChange={handlechange}
                  required
                />
                {formerror.email}
                <label for="email">Email </label>
              </div>
              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="phone"
                  onChange={handlechange}
                  required
                />
                {formerror.phone}
                <label for="phone">Phone No. </label>
              </div>
              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="city"
                  onChange={handlechange}
                  required
                />
                <label for="city">City</label>
              </div>

              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="pan"
                  onChange={handlechange}
                  required
                />
                {formerror.pan}
                <label for="pan">Pan No.</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="lname"
                  onChange={handlechange}
                  required
                />
                {formerror.lname}
                <label for="lname">Last Name</label>
              </div>

              <div className="input-field">
                <input
                  type="password"
                  className="input"
                  id="password"
                  onChange={handlechange}
                  required
                />
                {formerror.password}
                <label for="password">Password</label>
              </div>
              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="street"
                  onChange={handlechange}
                  required
                />

                <label for="street">Street</label>
              </div>

              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="state"
                  onChange={handlechange}
                  required
                />
                <label for="state">State</label>
              </div>
              <div className="input-field">
                <input
                  type="text"
                  className="input"
                  id="adhaar"
                  onChange={handlechange}
                  required
                />
                {formerror.adhaar}
                <label for="adhaar">Aadhaar No.</label>
              </div>
            </div>
          </div>
          <div className="input-field">
            <button className="submit" onClick={handleSubmit}>
              Register
            </button>
          </div>
          <div className="signin">
            <span>
              Already a member? <Link to="/login">Login</Link>
            </span>
          </div>
          <div className="signin">
            <span>
              <Link
                to="/digilocker"
                className="btn btn-md"
                style={{ backgroundColor: "#466cd9", color: "white" }}
              >
                Digilocker
              </Link>
            </span>
          </div>
          <br></br>
        </div>
      </div>
    </div>
  );
}

export default Signup;

import React, { useState } from "react";
import Header from "../Header/Header";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const BoardMember = () => {
  let state = useLocation();
  const navigation = useNavigate();
  const [apiboard, setApiboard] = useState([]);

  const apiteam = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setApiboard(data.board_members));
  };
  useEffect(() => {
    if ([null, ""].includes(state.state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      apiteam(state.state.some);
    }
  }, []);
  return (
    <div>
      <Header />

      <Breadcrumb style={{ margin: "10px" }}>
        <Breadcrumb.Item>
          <Link
            style={{ textDecoration: "none" }}
            to={"/search"}
            state={{
              some:
                state && state.state && state.state.some
                  ? state.state.some
                  : null,
            }}
          >
            {state && state.state && state.state.some ? state.state.some : null}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>board</Breadcrumb.Item>
      </Breadcrumb>

      <div className="responsive-container-block promoterscontainer">
        <p className="text-blk team-head-text">Management Team</p>
        <div className="responsive-container-block">
          {apiboard.map((item) => (
            <div className="responsive-cell-block wk-desk-3 wk-ipadp-3 wk-tab-6 wk-mobile-12 promoters-container">
              <div className="promoters">
                <div className="team-image-wrapper">
                  <img className="team-member-image" src={item.photo} />
                </div>
                <p className="text-blk name">{item.name}</p>
                <p className="text-blk position">{item.designation}</p>
                <p className="text-blk feature-text">{item.about}</p>
                <div className="social-icons">
                  <a href="https://www.twitter.com" target="_blank">
                    <img
                      className="twitter-icon"
                      src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/Icon.svg"
                    />
                  </a>
                  <a href="https://www.facebook.com" target="_blank">
                    <img
                      className="facebook-icon"
                      src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/Icon-1.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BoardMember;

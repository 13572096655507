import React from "react";
import Header from "../Header/Header";
import ProgressBar from "react-bootstrap/ProgressBar";
import Accordion from "react-bootstrap/Accordion";
import Badge from "react-bootstrap/Badge";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import "./Social.css";

const Social = () => {
  let state = useLocation();
  const navigation = useNavigate();
  const [apimanagment, setApimanagment] = useState();
  if (apimanagment && apimanagment.analyse) {
    var s = apimanagment.analyse.total;

    var tonArr = { pos: 0, neg: 0, neu: 0 };
    let counts = apimanagment.analyse.med_tonality.map((d, k) => {
      if (d._id === "Positive") {
        tonArr["pos"] = d.artcnt;
      }
      if (d._id === "Negative") {
        tonArr["neg"] = d.artcnt;
      }
      if (d._id === "Neutral") {
        tonArr["neu"] = d.artcnt;
      }
    });
    console.log(apimanagment.analyse.med_tonality, tonArr);
  }
  const apiSocial = (state) => {
    fetch(`${process.env.REACT_APP_PUBLIC_URL}search/${state}`)
      .then((res) => res.json())
      .then((data) => setApimanagment(data));
  };
  useEffect(() => {
    if ([null, ""].includes(state.state)) {
      console.log("state");
      navigation("/home");
      // return false;
    } else {
      apiSocial(state.state.some);
    }
  }, []);
  return (
    <div>
      <Header />
      <Breadcrumb style={{ margin: "10px", textDecoration: "none" }}>
        <Breadcrumb.Item>
          <Link
            style={{ textDecoration: "none" }}
            to={"/search"}
            state={{
              some:
                state && state.state && state.state.some
                  ? state.state.some
                  : null,
            }}
          >
            {state && state.state && state.state.some ? state.state.some : null}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Social Impact</Breadcrumb.Item>
      </Breadcrumb>
      <div className="Management">
        <h5>Social Impact</h5>
      </div>
      <div className="Management-accordion">
        <Accordion style={{ width: "95%" }} defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Objective Relevance </Accordion.Header>
            <Accordion.Body>No Data Found</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Geographical Presence</Accordion.Header>
            <Accordion.Body style={{ display: "flex" }}>
              {apimanagment &&
                apimanagment.presentIn &&
                apimanagment.presentIn.map((item) => <div>{item},</div>)}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Media Sentiments
              {tonArr && (
                <Badge bg="success" style={{ marginLeft: "10px" }}>
                  {((tonArr.pos / s) * 100).toFixed(2)}%
                </Badge>
              )}
              {tonArr && (
                <Badge bg="danger" style={{ marginLeft: "10px" }}>
                  {((tonArr.neg / s) * 100).toFixed(2)}%
                </Badge>
              )}
              {tonArr && (
                <Badge bg="warning" style={{ marginLeft: "10px" }}>
                  {((tonArr.neu / s) * 100).toFixed(2)}%
                </Badge>
              )}
            </Accordion.Header>

            <Accordion.Body
              style={{
                height: "200px",
                overflowY: "scroll",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            >
              {apimanagment &&
                apimanagment.analyse.med_tonality.map((item) =>
                  item.art.map((items, index) => (
                    <>
                      <div className="political">
                        <div style={{ width: "20%" }}>{items.headline}</div>
                        <Link target="_blank" to={items.link}>
                          {items.source}
                        </Link>
                        <div>{moment(items.date).format("DD-MM-YYYY")}</div>
                      </div>
                      <hr />
                    </>
                  ))
                )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Public Sentiments</Accordion.Header>
            <Accordion.Body style={{ height: "200px", overflowY: "scroll" }}>
              {apimanagment &&
                apimanagment.analyse.pub_tonality.map((item) =>
                  item.art.map((items, index) => (
                    <div className="political">
                      <div style={{ width: "20%" }}>{items.headline}</div>
                      <Link target="_blank" to={items.link}>
                        {items.source}
                      </Link>
                      <div>{moment(items.date).format("DD-MM-YYYY")}</div>
                    </div>
                  ))
                )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Social;
